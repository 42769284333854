:root {
  --modal-background-color: rgba(86, 86, 86, 0.5);
  --modal-content-background-color: #fff;
  --modal-shadow-color: rgba(59, 59, 59, 0.15);

  --scrollbar-track-color: #fff;
  --scrollbar-thumb-color: #969696;

  --list-item-hover-color: rgba(239, 239, 239, 0.5);

  --button-default-color: transparent;
  --button-default-hover-color: rgba(236, 236, 236, 0.3);
  --button-default-active-color: rgba(231, 231, 231, 0.5);

  --accent-color: #004264;

  --text-color: #181818;
  --sub-text-color: #656565;
  --inverted-text-color: #fff;

  --border-color: #e3e3e3;
}

.theme-dark {
  --modal-background-color: rgba(40, 40, 40, 0.5);
  --modal-content-background-color: #012E45;
  --modal-shadow-color: rgba(0, 0, 0, 0.15);

  --scrollbar-track-color: #012E45;
  --scrollbar-thumb-color: #004264;

  --list-item-hover-color: rgba(10, 56, 84, 0.5);

  --button-default-hover-color: rgba(101, 101, 101, 0.3);
  --button-default-active-color: rgba(101, 101, 101, 0.2);

  --accent-color: #e3e3e3;

  --text-color: #d9d9d9;
  --sub-text-color: rgb(144, 153, 192);
  --inverted-text-color: #000;

  --border-color: #004264;
}
