.update {
  height: 100%;
  cursor: pointer;
  transition: background-color var(--transition);

  &:hover {
    background-color: var(--list-item-hover-color);
  }

  &NotWatched {
    .updateDate {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &::after {
        content: 'New';
        margin-left: 10px;
        padding: 2px 6px;
        display: block;
        border-radius: 4px;
        background-color: var(--accent-color);
        color: var(--inverted-text-color);
      }
    }
  }

  &Date {
    font-size: 12px;
    color: var(--sub-text-color);
  }

  &Title {
    max-height: 300px;
    height: 100%;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font: {
      size: 18px;
      weight: 600;
    };
    color: var(--text-color);
  }
}
