@import "src/styles/templates.scss";

.modal {
  &Container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    background-color: var(--modal-background-color);
  }

  &Inner {
    min-width: 10vw;
    max-width: 90vw;
    margin: auto;
    background-color: var(--modal-content-background-color);
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: 5px 5px 10px var(--modal-shadow-color);
  }

  &Header {
    max-width: 100%;
    height: 80px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-color);
  }

  &CloseButton {
    width: 48px;
    height: 48px;
    margin-left: auto;
    padding: 8px;
    align-self: flex-start;
    display: flex;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &CloseIcon {
    width: 18px;
    height: 18px;
    margin: auto;

    * {
      stroke: var(--sub-text-color);
    }
  }

  &ContentWrapper {
    @extend %styled-scrollbar;
    height: calc(100% - 80px);
    min-height: 10vh;
    max-height: 90vh;
    position: relative;
    overflow: auto;
  }
}
