.button {
  padding: 8px;

  &Icon {
    width: 28px;
    height: 28px;
    transform: rotate(180deg);

    * {
      fill: var(--sub-text-color);
    }
  }
}
