.empty {
  margin: auto;
  color: var(--sub-text-color);

  &Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &Extra {
    margin-bottom: 20px;
  }
}
