.list {
  width: 100%;
  display: flex;
  flex-flow: column;

  &Container {
    width: 100%;
    display: flex;
    flex-flow: column;
  }

  &Header {
    padding: 20px;
    color: var(--text-color);
    font-size: 18px;
    border-bottom: 2px solid var(--border-color);
  }
}
