@import "src/styles/templates.scss";

.update {
  max-width: 800px;
  max-height: 700px;
  width: 100%;
  height: 100%;

  &Header {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &Title {
      width: 100%;
      height: 100%;
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &Date {
    font-size: 12px;
    color: var(--sub-text-color);
  }

  &SlidesContainer {
    width: 100%;
    height: 100%;
    align-self: flex-start;
  }

  &Title {
    color: var(--text-color);
  }
}
