@import './palette.scss';
@import './constants.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  -webkit-font-smoothing: antialiased; /* WebKit  */
}

html {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
