.details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &SlideInner {
    max-width: 100%;
    margin-top: 20px;
    color: var(--text-color);
  }

  &SlideImage {
    width: 100%;
    margin: 20px 0;
  }
}
