.listItem {
  padding: 0 20px;
  color: var(--text-color);

  &:not(:last-child) {
    .listItemInner {
      border-bottom: 1px solid var(--border-color);
    }
  }

  &Inner {
    padding: 20px 0;
  }
}
